import styles from "./OurProcessItem.module.scss";

import * as React from "react";
import { EntityOurProcessItem } from "@lib/entities/EntityOurProcessItem";
import HeadingSmall from "@components/heading-small/HeadingSmall";
import BodyLarge from "@components/bodyLarge/BodyLarge";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";
import { motion, useViewportScroll } from "framer-motion";
import Dot from "./Dot";

const OurProcessItem = (props: EntityOurProcessItem) => {
  return (
    <>
      <div className={styles.content}>
        <motion.div
          className={styles.heading}
          style={{ opacity: 0, x: -30 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, margin: "0px 0px -50px 0px" }}
          transition={{ ease: "easeOut", duration: 0.6 }}
        >
          <HeadingSmall as="h2">{props.heading}</HeadingSmall>
        </motion.div>
        <motion.div
          className={styles.copy}
          style={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true, margin: "0px 0px -100px 0px" }}
          transition={{ ease: "linear", duration: 0.4 }}
        >
          <BodyLarge>{props.paragraph}</BodyLarge>
        </motion.div>
        <ul className={styles.list}>
          {props.items.map((item, index) => {
            return (
              <motion.li
                className={styles.listItem}
                key={`${item.copy}-${index}`}
                style={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, margin: "0px 0px -100px 0px" }}
                transition={{ ease: "linear", duration: 0.4 }}
              >
                {item.copy}
              </motion.li>
            );
          })}
        </ul>
      </div>
      <div className={styles.imageWrapper}>
        {props.image.responsiveImage && (
          <ResponsiveImage responsiveImage={props.image.responsiveImage} />
        )}
      </div>
      <Dot className={styles.dot} />
    </>
  );
};

export default OurProcessItem;
