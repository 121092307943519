import styles from "./Assets.module.scss";

import * as React from "react";
import { EntityImage } from "@lib/entities/EntityImage";
import { EntityMap } from "@lib/entities/EntityMap";
import { ASSETS } from "@lib/enums/assets";
import Image from "@components/image/Image";
import Map from "@components/map/Map";

export type TAssets = {
  assets: (EntityImage | EntityMap)[];
};

const Assets = (props: TAssets) => {
  const assetsWrapperClassName =
    props.assets.length === 1
      ? styles.imagesWrapperOne
      : props.assets.length === 2
      ? styles.imagesWrapperTwo
      : props.assets.length === 3
      ? styles.imagesWrapperThree
      : undefined;

  return (
    <>
      {props.assets.length > 0 && (
        <div className={assetsWrapperClassName}>
          {props.assets.map((asset, index) => {
            switch (asset._modelApiKey) {
              case ASSETS.Image: {
                return (
                  <div className={styles.imageWrapper} key={index}>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <Image {...asset} />
                  </div>
                );
              }
              case ASSETS.Map: {
                return (
                  <div className={styles.imageWrapper} key={index}>
                    <Map {...asset} />
                  </div>
                );
              }
            }
          })}
        </div>
      )}
    </>
  );
};

export default Assets;
