import styles from "./BlockTestimonialClient.module.scss";

import * as React from "react";

import { IBlockTestimonialClient } from "@lib/types/IBlockTestimonialClient";

import Container from "@components/containers/Container";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Columns, { COLUMN_VARIANTS } from "@components/columns/Columns";
import Eyebrow from "@components/eyebrow/Eyebrow";
import Testimonial, { TESTIMONIAL_VARIANTS } from "@components/testimonial/Testimonial";
import { IReferenceClient } from "@lib/types/IReferenceClient";
import useWindowSize from "@hooks/useWindowSize";
import { cx } from "helpers/classNames";
import { notEmpty } from "helpers/notEmpty";
import { useReferenceClients } from "@hooks/useReferenceClients";
import { AnimatePresence, motion } from "framer-motion";
import useMeasure from "react-use-measure";

// whenever a testimonial is open, the timer will be killed.
// after all are closed, the timer will continue again.

const BlockTestimonialClient = (props: IBlockTestimonialClient) => {
  const { width } = useWindowSize();
  const [listRef, bounds] = useMeasure();
  const autoplayTimeout = React.useRef<ReturnType<typeof setTimeout>>();
  const [isAutoplayPaused, setIsAutoplayPaused] = React.useState<boolean>(false);
  const references = React.useRef<HTMLUListElement>(null);
  const [list, setList] = React.useState<IReferenceClient[]>(
    props.useAllReferences ? [] : props.references || []
  );
  // pagination for autoplay
  const pageAmount = 3;
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const sliceStart = props.autoplay ? pageIndex * pageAmount : 0;
  const sliceEnd = props.autoplay ? pageIndex * pageAmount + pageAmount : list.length;

  // load all references when required
  const { data, count, isLoading, isError } = useReferenceClients({
    active: props.useAllReferences
  });
  React.useEffect(() => {
    if (!isLoading) {
      setList(data || []);
    }
  }, [isLoading, data]);

  // autoPlay
  React.useEffect(() => {
    if (props.autoplay && !isLoading && !isAutoplayPaused) {
      autoplayTimeout.current = setTimeout(() => {
        let nextIndex = pageIndex + 1;
        if (nextIndex > Math.floor(count / pageAmount)) {
          nextIndex = 0;
        }
        setPageIndex(nextIndex);
      }, 10000);
    }
    return () => {
      if (autoplayTimeout.current) {
        clearTimeout(autoplayTimeout.current);
      }
    };
  }, [isAutoplayPaused, isLoading, pageIndex, count, props.autoplay]);

  // split into columns
  const numColumns = width && width < 768 ? 1 : 3;
  const columns = list
    .slice(sliceStart, sliceEnd)
    .reduce(
      (prev, curr, index, array) => {
        const moduloColumn = index % numColumns;
        const col: IReferenceClient[] = prev[moduloColumn] || [];
        prev[moduloColumn] = col;
        col.push(curr);
        return prev;
      },
      [...Array(numColumns).map<IReferenceClient[]>(() => [])]
    )
    .filter(notEmpty);

  return (
    <Container
      as="section"
      labelledby="Testimonials"
      className={cx(styles.container, props.isInverted ? styles.isInverted : false, "isInverted")}
      key={props.id}
      trackingCategory={"Testimonial"}
    >
      <Columns variant={COLUMN_VARIANTS.Wide}>
        <div>
          <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
        </div>
        <div>
          <div className={styles.heading}>
            <HeadingLarge as="div">{props.heading}</HeadingLarge>
          </div>
        </div>
      </Columns>
      <motion.div animate={{ height: bounds.height }} transition={{ duration: 1 }}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={pageIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            ref={listRef}
            className={styles.columns}
          >
            {columns.map((column, index) => {
              return (
                <div key={`${pageIndex}-${index}`} className={styles.column}>
                  <ul className={styles.list} ref={references}>
                    {column.map((reference) => {
                      return (
                        <li className={styles.listItem} key={reference.id}>
                          <Testimonial
                            reference={reference}
                            isInverted={props.isInverted}
                            variant={
                              props.isVariantShort
                                ? TESTIMONIAL_VARIANTS.Short
                                : TESTIMONIAL_VARIANTS.Default
                            }
                            onButtonToggle={(isOpen: boolean) => {
                              setIsAutoplayPaused(isOpen);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </motion.div>
        </AnimatePresence>
      </motion.div>
      {isError && (
        <div className={styles.error}>Oops! An error occurred while loading the references.</div>
      )}
    </Container>
  );
};

export default BlockTestimonialClient;
