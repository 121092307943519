// FIXME: add typescript stuff

import React, { useState, useEffect } from "react";

const useElementOffsetTop = (ref: React.RefObject<HTMLElement>) => {
  const [elementOffsetTop, setElementOffsetTop] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      if (ref.current) {
        var offset = ref.current.getBoundingClientRect();
        setElementOffsetTop(offset.top);
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, [ref]);

  return elementOffsetTop;
};

export default useElementOffsetTop;
