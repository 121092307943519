import styles from "./BlockMeetTheTeam.module.scss";

import { IBlockMeetTheTeam } from "@lib/types/IBlockMeetTheTeam";

import Container from "@components/containers/Container";
import Eyebrow from "@components/eyebrow/Eyebrow";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Columns from "@components/columns/Columns";
import Member from "@components/member/Member";
import { EntityMember } from "@lib/entities/EntityMember";
import useWindowSize from "@hooks/useWindowSize";

const BlockMeetTheTeam = (props: IBlockMeetTheTeam) => {
  const { width } = useWindowSize();
  const members = props.members;

  let items: (EntityMember | null)[] = [];
  if (width && width < 1024) {
    members.forEach((member) => {
      items.push(member);
    });
  } else if (members.length === 7) {
    members.forEach((member, index) => {
      items.push(member);
      if (index === 3) {
        items.push(null);
      }
    });
  } else if (members.length === 6 || members.length >= 7) {
    members.forEach((member, index) => {
      items.push(member);
      if (index % 6 === 2) {
        items.push(null);
        items.push(null);
      }
    });
  } else {
    members.forEach((member) => {
      items.push(member);
    });
  }

  return (
    <Container
      as="section"
      labelledby="The Team"
      className={styles.container}
      key={props.id}
      trackingCategory={"Meet The Team"}
    >
      <Columns>
        <div>
          <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
        </div>
        <HeadingLarge as="div" className={styles.heading}>
          {props.heading}
        </HeadingLarge>
      </Columns>
      <ul className={styles.list}>
        {items.map((member, index) => {
          return (
            <li key={member?.id || index} className={styles.listItem}>
              {member && <Member member={member} />}
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default BlockMeetTheTeam;
