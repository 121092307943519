import styles from "./HowWeWorkItem.module.scss";

import * as React from "react";
import { EntityHowWeWorkItem } from "@lib/entities/EntityHowWeWorkItem";
import HeadingSmall from "@components/heading-small/HeadingSmall";
import RichText from "@components/rich-text/RichText";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";

const HowWeWorkItem = (props: EntityHowWeWorkItem) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <ResponsiveImage {...props.image} layout="responsive" />
      </div>
      <div className={styles.heading}>
        <HeadingSmall as="h2">{props.heading}</HeadingSmall>
      </div>
      <div className={styles.richtext}>
        <RichText richtext={props.copy} />
      </div>
    </div>
  );
};

export default HowWeWorkItem;
