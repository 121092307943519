import styles from "./ButtonJob.module.scss";

import * as React from "react";
import Button from "@components/buttons/Button";
import { EntityJob } from "@lib/entities/EntityJob";
import Pill from "@components/pill/Pill";
import useWindowSize from "@hooks/useWindowSize";
import { EntityGlobal } from "@lib/entities/EntityGlobal";

interface IButtonJob {
  job: EntityJob;
  global: EntityGlobal;
}

const ButtonJob = (props: IButtonJob) => {
  const { width } = useWindowSize();
  const { job } = props;

  const tagsColumnOne = width
    ? width < 1024
      ? job.getTags()
      : job.getTagsInTagGroups(props.global.tagGroupsColumnOne)
    : job.getTagsInTagGroups(props.global.tagGroupsColumnOne);
  const tagsColumnTwo = width
    ? width < 1024
      ? []
      : job.getTagsInTagGroups(props.global.tagGroupsColumnTwo)
    : job.getTagsInTagGroups(props.global.tagGroupsColumnTwo);

  const amountVisibleInColumnOne = 3;
  const amountVisibleInColumnTwo = 3;

  const amountColumnOne = Math.max(0, tagsColumnOne.length - amountVisibleInColumnOne);
  const amountColumnTwo = Math.max(0, tagsColumnTwo.length - amountVisibleInColumnTwo);

  return (
    <Button className={styles.button} page={job.getPage()}>
      <div className={styles.title}>{job.title}</div>
      <ul className={styles.listOne}>
        {tagsColumnOne.slice(0, amountVisibleInColumnOne).map((tag) => {
          return (
            <li key={tag.id} className={styles.listItem}>
              <Pill tag={tag}>{tag.title}</Pill>
            </li>
          );
        })}
        {amountColumnOne > 0 && (
          <li key="more" className={styles.listItem}>
            <Pill>{`${amountColumnOne}+`}</Pill>
          </li>
        )}
      </ul>
      <ul className={styles.listTwo}>
        {tagsColumnTwo.slice(0, amountVisibleInColumnTwo).map((tag) => {
          return (
            <li key={tag.id} className={styles.listItem}>
              <Pill tag={tag}>{tag.title}</Pill>
            </li>
          );
        })}
        {amountColumnTwo > 0 && (
          <li key="more" className={styles.listItem}>
            <Pill>{`${amountColumnTwo}+`}</Pill>
          </li>
        )}
      </ul>
    </Button>
  );
};

export default ButtonJob;
