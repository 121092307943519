import styles from "./ButtonHeaderTypeA.module.scss";

import * as React from "react";

import { IButtonHeaderTypeAExternal } from "@lib/types/IButtonHeaderTypeAExternal";
import { IButtonHeaderTypeAInternal } from "@lib/types/IButtonHeaderTypeAInternal";

import Button from "@components/buttons/Button";

interface IProps {
  button: IButtonHeaderTypeAExternal | IButtonHeaderTypeAInternal;
  onClick?: () => void;
}

const ButtonHeaderTypeA = (props: IProps) => {
  const { button, ...rest } = props;

  return (
    <Button className={styles.button} {...props.button} {...rest}>
      <span className={styles.regular}>
        <span className={styles.eyebrow}>{button.eyebrow}</span>
        <span className={styles.title}>{button.title}</span>
        <svg
          className={styles.icon}
          width="40"
          height="24"
          viewBox="0 0 40 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M27.665 0H30.7L40 11.96 30.662 24h-2.997l7.549-9.681.467-.57c.156-.19.298-.38.428-.57H0v-2.4h36.07a9.289 9.289 0 0 1-.467-.528 11.05 11.05 0 0 0-.506-.651L27.665 0Z" />
        </svg>
      </span>
      <span className={styles.hover}>
        <span className={styles.eyebrow}>{button.eyebrow}</span>
        <span className={styles.title}>{button.title}</span>
        <svg
          className={styles.icon}
          width="40"
          height="24"
          viewBox="0 0 40 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M27.665 0H30.7L40 11.96 30.662 24h-2.997l7.549-9.681.467-.57c.156-.19.298-.38.428-.57H0v-2.4h36.07a9.289 9.289 0 0 1-.467-.528 11.05 11.05 0 0 0-.506-.651L27.665 0Z" />
        </svg>
      </span>
    </Button>
  );
};

export default ButtonHeaderTypeA;
