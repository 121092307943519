import { EntityInternalLink } from "@lib/entities/EntityInternalLink";
import { EntityExternalLink } from "@lib/entities/EntityExternalLink";
import { NextRouter } from "next/router";

export const handleClick =
  (link: Array<EntityInternalLink | EntityExternalLink>, router: NextRouter) => () => {
    const l = link[0];
    if (l._modelApiKey === "internal_link") {
      router.push(l.page.slug);
    } else if (l._modelApiKey === "external_link") {
      if (l.openInNewWindow) {
        window.open(l.url, "_blank")?.focus();
      } else {
        window.location.href = l.url;
      }
    }
  };
