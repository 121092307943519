import styles from "./BlockPurpleBanner.module.scss";

import * as React from "react";
import { IBlockPurpleBanner } from "@lib/types/IBlockPurpleBanner";

import Container from "@components/containers/Container";
import RichText from "@components/rich-text/RichText";
import { useRouter } from "next/router";
import { handleClick } from "./handlers";
import { cx } from "helpers/classNames";

const BlockPurpleBanner = (props: IBlockPurpleBanner) => {
  const router = useRouter();

  const className = props.useDarkBackground ? styles.containerOnDarkBackground : styles.container;

  let hasValidLink = false;
  if (props.link.length > 0) {
    const isValidInternalLink =
      props.link[0]._modelApiKey === "internal_link" && props.link[0].page?.slug;
    const isValidExternalLink = props.link[0]._modelApiKey === "external_link" && props.link[0].url;
    hasValidLink = !!(isValidInternalLink || isValidExternalLink);
  }

  let clickHandler = undefined;
  if (hasValidLink) {
    clickHandler = handleClick(props.link, router);
  }

  return (
    <Container
      as="section"
      labelledby="Info"
      className={className}
      key={props.id}
      trackingCategory={props.heading}
    >
      <div
        className={cx(styles.banner, hasValidLink ? styles.link : undefined)}
        onClick={clickHandler}
      >
        <h1 className={styles.heading}>{props.heading}</h1>
        <div className={styles.richtext}>
          <RichText trackingCategory="purple banner" richtext={props.copy} />
        </div>
      </div>
    </Container>
  );
};

export default BlockPurpleBanner;
