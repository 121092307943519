import styles from "./IntroText.module.scss";

import * as React from "react";
import useWindowSize from "@hooks/useWindowSize";

export type IIntroText = {
  copy?: string;
  minHeightMobile: number;
  minHeightDesktop: number;
  onStart?: () => void;
  onComplete?: () => void;
};

interface IAnimation {
  frame: number;
  chars: IChar[];
}

interface IChar {
  char: string;
  values: string[];
}

const IntroText = (props: IIntroText) => {
  const [animation, setAnimation] = React.useState<IAnimation>({ frame: 0, chars: [] });

  const pixelSize = 40;

  const { width, height } = useWindowSize();
  const copy = React.useRef<HTMLDivElement>(null);

  const minHeight = width && width < 1024 ? props.minHeightMobile : props.minHeightDesktop;

  const myWidth = width || 0;
  const myHeight = Math.max(minHeight, height || 0);

  React.useEffect(() => {
    if (!copy.current) return;

    let x = Math.ceil((myWidth * 0.5) / pixelSize) * pixelSize;
    x -= Math.ceil((Math.min(1440, myWidth) * 0.5) / pixelSize) * pixelSize;
    x += 2 * pixelSize;

    let y = Math.ceil((myHeight * 0.5) / pixelSize) * pixelSize;
    if (myHeight <= 540) {
      y -= pixelSize;
    }

    copy.current.style.left = `${x}px`;
    copy.current.style.bottom = `${y}px`;
  }, [animation.chars, myWidth, myHeight]);

  React.useEffect(() => {
    var possible = "!@#$%^&*(){}[]-+:;";

    let charIndex = 0;
    const numScramble = 4;
    const numWord = 140; // fill each values array with this amount of values

    function getRandomChar(char: string) {
      return char === " " ? " " : possible.charAt(Math.floor(Math.random() * possible.length));
    }

    if (props.copy) {
      const chars = props.copy
        .split("\n")
        .map((line) => {
          return line
            .trimEnd()
            .split("")
            .map((char) => {
              const start: string[] = Array.from(Array(charIndex + 1)).map(() => {
                return " ";
              });

              const scramble: string[] = Array.from(Array(numScramble)).map(() => {
                return getRandomChar(char);
              });

              const word: string[] = Array.from(Array(numWord - (charIndex + 1) - numScramble)).map(
                () => {
                  return char;
                }
              );

              // const numOutro = Math.round(Math.random() * 20) + 4;
              // const outro: string[] = Array.from(Array(numOutro)).map((_, index) => {
              //   if (index >= numOutro - 1) return " "; // end
              //   if (index < numOutro - 5) return char;
              //   return getRandomChar(char);
              // });

              charIndex++;

              return {
                char,
                values: start.concat(scramble, word)
              };
            });
        })
        .flat();

      setAnimation({
        frame: 0,
        chars
      });
    }
  }, [props.copy]);

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const maxFrameLength = animation.chars.reduce((prev, curr) => {
      return Math.max(curr.values.length, prev);
    }, 0);

    function update() {
      setAnimation({
        frame: animation.frame + 1,
        chars: animation.chars
      });
    }

    if (maxFrameLength <= 0) {
      // starting up, waiting to fill chars
    } else if (animation.frame === 0 && maxFrameLength > 0) {
      if (props.onStart) {
        props.onStart();
      }
      update();
    } else if (animation.frame < maxFrameLength) {
      timeout = setTimeout(update, 40);
    } else {
      // if (props.onComplete) {
      //   props.onComplete();
      // }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [animation]);

  if (!props.copy || animation.chars.length <= 0) return null;

  let indexChar = 0;
  const lines = props.copy.split("\n").map((line, index) => {
    return (
      <span className={styles.line} key={index}>
        {line
          .trimEnd()
          .split("")
          .map((char, index) => {
            const frame = Math.min(animation.frame, animation.chars[indexChar].values.length - 1);
            const value = animation.chars[indexChar].values[frame];
            const jsx = (
              <span className={styles.char} key={index} style={{ opacity: value === " " ? 0 : 1 }}>
                {value}
              </span>
            );
            indexChar++;
            return jsx;
          })}
      </span>
    );
  });

  return (
    <div className={styles.container}>
      <div ref={copy} className={styles.copy}>
        {lines}
      </div>
    </div>
  );
};

export default IntroText;
