import styles from "./BlockCompaniesTypeA.module.scss";

import { IBlockCompaniesTypeA } from "@lib/types/IBlockCompaniesTypeA";

import Container from "@components/containers/Container";
import Eyebrow from "@components/eyebrow/Eyebrow";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";
import useWindowSize from "@hooks/useWindowSize";
import { pxToRem } from "helpers/pxToRem";

import Marquee from "react-fast-marquee";
import { ICompany } from "@lib/types/ICompany";

const BlockCompaniesTypeA = (props: IBlockCompaniesTypeA) => {
  const { width, documentFontSize } = useWindowSize();

  const desiredImageHeight = width ? (width < 1024 ? 60 : 80) : 80;

  const totalWidth = props.companies.reduce<number>((prev, curr: ICompany) => {
    prev += curr.logoWhite.responsiveImage.width;
    return prev;
  }, 0);

  const times = width ? Math.ceil(width / totalWidth) : 1;
  const companies = Array.from(Array(times))
    .map(() => {
      return props.companies;
    })
    .flat();

  return (
    <div className={styles.container}>
      <Container
        as="section"
        labelledby="Clients"
        trackingCategory={"Companies (Type A)"}
        key={props.id}
      >
        <div className={styles.eyebrow}>
          <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
        </div>
      </Container>
      <div className={styles.marqueeWrapper}>
        <div className={styles.list}>
          <Marquee gradient={false}>
            {companies.map((company, index) => {
              return (
                <div
                  key={index}
                  className={styles.listItem}
                  style={{
                    height: desiredImageHeight,
                    width:
                      (company.logoBlack.responsiveImage.width * desiredImageHeight) /
                      (company.logoBlack.responsiveImage.height || 1)
                  }}
                >
                  {documentFontSize && (
                    <>
                      <div
                        className={styles.listItemSizer}
                        style={{
                          width: `${pxToRem({
                            value: company.logoWhite.responsiveImage.width * 0.5,
                            documentFontSize
                          })}px`
                        }}
                      />
                      <ResponsiveImage
                        {...company.logoBlack}
                        layout="fill"
                        objectFit="contain"
                        objectPosition="0% 50%"
                        fadeInDuration={0}
                        usePlaceholder={false}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BlockCompaniesTypeA;
