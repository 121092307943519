import styles from "./BlockHeaderContact.module.scss";

import Container from "@components/containers/Container";
import { EntityBlockHeaderContact } from "@lib/entities/EntityBlockHeaderContact";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Button from "@components/buttons/Button";
import * as Analytics from "@lib/tracking/Analytics";

const BlockHeaderContact = (props: EntityBlockHeaderContact) => {
  return (
    <Container
      as="section"
      labelledby="Header"
      trackingCategory={"Header (Contact)"}
      className={styles.container}
      key={props.id}
    >
      <div className={styles.content}>
        <div className={styles.heading}>
          <HeadingLarge as="h1">{props.heading}</HeadingLarge>
        </div>
        <div className={styles.details}>
          <div className={styles.phone}>
            <Button
              url={`tel:${props.phone}`}
              onClick={() => {
                Analytics.trackEvent("click", "header contact", props.phone, `tel:${props.phone}`);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M26 29H25.83C6.18 27.87 3.39 11.29 3 6.23C2.96858 5.83658 3.01509 5.44082 3.13688 5.06541C3.25866 4.69 3.45333 4.3423 3.70972 4.04225C3.96611 3.7422 4.27919 3.49569 4.63101 3.31684C4.98284 3.13799 5.3665 3.03032 5.76 3H11.27C11.6706 2.99961 12.062 3.11951 12.3936 3.34416C12.7253 3.56881 12.9818 3.88787 13.13 4.26L14.65 8C14.7964 8.36355 14.8327 8.76208 14.7544 9.14609C14.6762 9.5301 14.4869 9.88267 14.21 10.16L12.08 12.31C12.4127 14.2007 13.3182 15.9437 14.6739 17.303C16.0296 18.6622 17.7701 19.5723 19.66 19.91L21.83 17.76C22.1115 17.4862 22.4674 17.3013 22.8533 17.2283C23.2392 17.1554 23.638 17.1977 24 17.35L27.77 18.86C28.1365 19.0129 28.4492 19.2714 28.6683 19.6027C28.8873 19.9339 29.0028 20.3229 29 20.72V26C29 26.7956 28.6839 27.5587 28.1213 28.1213C27.5587 28.6839 26.7957 29 26 29ZM6 5C5.73479 5 5.48043 5.10536 5.2929 5.29289C5.10536 5.48043 5 5.73478 5 6V6.08C5.46 12 8.41 26 25.94 27C26.0714 27.0081 26.2031 26.9902 26.3275 26.9473C26.4519 26.9045 26.5667 26.8374 26.6652 26.7501C26.7637 26.6628 26.8439 26.5568 26.9014 26.4384C26.9588 26.32 26.9924 26.1914 27 26.06V20.72L23.23 19.21L20.36 22.06L19.88 22C11.18 20.91 10 12.21 10 12.12L9.94 11.64L12.78 8.77L11.28 5H6Z" />
              </svg>

              <span>{props.phone}</span>
            </Button>
          </div>
          <div className={styles.mail}>
            <Button
              url={`mailto:${props.mail}`}
              onClick={() => {
                Analytics.trackEvent("click", "header contact", props.mail, `mailto:${props.mail}`);
              }}
            >
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V24C2 24.5304 2.21071 25.0391 2.58579 25.4142C2.96086 25.7893 3.46957 26 4 26H28C28.5304 26 29.0391 25.7893 29.4142 25.4142C29.7893 25.0391 30 24.5304 30 24V8C30 7.46957 29.7893 6.96086 29.4142 6.58579C29.0391 6.21071 28.5304 6 28 6ZM25.8 8L16 14.78L6.2 8H25.8ZM4 24V8.91L15.43 16.82C15.5974 16.9361 15.7963 16.9984 16 16.9984C16.2037 16.9984 16.4026 16.9361 16.57 16.82L28 8.91V24H4Z" />
              </svg>
              <span>{props.mail}</span>
            </Button>
          </div>
          <div className={styles.address}>
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8H8V12H10V8Z" />
              <path d="M10 14H8V18H10V14Z" />
              <path d="M16 8H14V12H16V8Z" />
              <path d="M16 14H14V18H16V14Z" />
              <path d="M10 20H8V24H10V20Z" />
              <path d="M16 20H14V24H16V20Z" />
              <path d="M30 14C30 13.4696 29.7893 12.9609 29.4142 12.5858C29.0391 12.2107 28.5304 12 28 12H22V4C22 3.46957 21.7893 2.96086 21.4142 2.58579C21.0391 2.21071 20.5304 2 20 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V30H30V14ZM4 4H20V28H4V4ZM22 28V14H28V28H22Z" />
            </svg>

            <span>{props.address}</span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BlockHeaderContact;
