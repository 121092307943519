//
// Initialize a texture and load an image.
// When the image finished loading copy it into the texture.
//

export interface CustomTexture extends WebGLTexture {
  update: () => void;
}

export const loadVideoTexture = (
  gl: WebGLRenderingContext,
  url: string,
  onLoadComplete?: () => void
): CustomTexture => {
  const texture = gl.createTexture() as CustomTexture;
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Because images have to be downloaded over the internet
  // they might take a moment until they are ready.
  // Until then put a single pixel in the texture so we can
  // use it immediately. When the image has finished downloading
  // we'll update the texture with the contents of the image.
  const level = 0;
  const internalFormat = gl.RGBA;
  const width = 1;
  const height = 1;
  const border = 0;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const pixel = new Uint8Array([0, 0, 0, 0]); // tranparent

  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    pixel
  );

  const video = document.createElement("video");
  const image = new Image();

  let imageReady = false;

  const handleVideoReady = () => {
    video.removeEventListener("loadedmetadata", handleVideoReady);
    const playPromise = video.play();
    playPromise
      .then(() => {
        // works
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  image.onload = function () {
    imageReady = true;

    video.playsInline = true;
    video.muted = true;
    video.loop = true;
    video.autoplay = true;
    video.src = url;
    video.addEventListener("loadedmetadata", handleVideoReady);

    let initialPlay = true;
    video.addEventListener("canplay", () => {
      if (!initialPlay) return;

      gl.bindTexture(gl.TEXTURE_2D, texture);

      if (video && !video.paused && video.currentTime > 0.01) {
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, video);
      } else {
        gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
      }

      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

      initialPlay = false;
      if (onLoadComplete) {
        onLoadComplete();
      }
    });
  };
  image.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=";

  texture.update = () => {
    // send the data to the gpu
    if (!video.paused && video.currentTime > 0.01) {
      gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, video);
    } else if (image && imageReady) {
      gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
    }
  };

  return texture;
};
