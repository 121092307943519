import styles from "./Loader.module.scss";

import React from "react";

interface ILoader {
  characters?: string;
  speed?: number;
}

const Loader = (props: ILoader) => {
  const characters = props.characters || "\\|/-";
  const speed = props.speed || 100;

  const [tick, setTick] = React.useState<number>(0);

  React.useEffect(() => {
    const interval = setTimeout(() => {
      const newTick = (tick + 1) % characters.length;
      setTick(newTick);
    }, speed);

    return () => {
      clearTimeout(interval);
    };
  }, [tick, speed, characters.length]);

  return <span className={styles.loader}>Loading jobs... {characters[tick]}</span>;
};

export default Loader;
