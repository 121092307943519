import styles from "./BlockHeaderTypeB.module.scss";

import { IBlockHeaderTypeB } from "@lib/types/IBlockHeaderTypeB";

import Container from "@components/containers/Container";
import HeadingLarge from "@components/heading-large/HeadingLarge";

const BlockHeaderTypeB = (props: IBlockHeaderTypeB) => {
  return (
    <Container
      as="section"
      labelledby="Header"
      className={styles.container}
      key={props.id}
      trackingCategory={"Header (Type B)"}
    >
      <div className={styles.content}>
        <HeadingLarge as="h1">{props.heading}</HeadingLarge>
      </div>
    </Container>
  );
};

export default BlockHeaderTypeB;
