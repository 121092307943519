import type { NextPage, InferGetServerSidePropsType, GetServerSideProps } from "next";
import { SeoOrFaviconTag, useQuerySubscription } from "react-datocms";

import { request } from "@lib/datocms";
import { EntityNavigation } from "@lib/entities/EntityNavigation";
import { EntityFooter } from "@lib/entities/EntityFooter";
import { EntityPage } from "@lib/entities/EntityPage";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import {
  querySite,
  queryGlobal,
  queryPage,
  queryNavigation,
  queryFooter,
  TPageRequest
} from "@lib/queries";

import Blocks from "@components/blocks";
import Layout from "@components/layouts/Layout";
import { IPage } from "@lib/types/IPage";
import { queryJobTags } from "@lib/queries/jobTags/jobTags";
import { EntityJobTags } from "@lib/entities/EntityJobTags";
import { testRedirect } from "helpers/testRedirect";
import PageError from "@components/page-error/PageError";
import { EntitySite } from "@lib/entities/EntitySite";
import { IJobTags } from "@lib/types/IJobTags";

type NextPageWithLayout = NextPage & {
  getLayout?: (props: TPageLayout) => React.ReactNode;
};

const Page: NextPageWithLayout = ({
  subscriptionPage,
  subscriptionJobsFilter,
  subscriptionJobTags,
  subscriptionGlobal
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { data: dataGlobal } = useQuerySubscription(subscriptionGlobal);
  const { data: dataPage } = useQuerySubscription(subscriptionPage);
  const { data: dataJobTags } = useQuerySubscription(subscriptionJobTags);

  const page = dataPage.data ? EntityPage.createFromObject(dataPage.data) : null;
  const jobTags = EntityJobTags.createFromObject(dataJobTags || {});
  const global = EntityGlobal.createFromObject(dataGlobal.data);

  // console.log({ page, global, navigation, footer, jobsFilter });

  if (!page) {
    return <PageError global={global} />;
  }

  return <Blocks key={page.slug} blocks={page.blocks} jobTags={jobTags} global={global} />;
};

export default Page;

export type TPageLayout = {
  page: React.ReactNode;
  preview?: boolean;
  seo?: SeoOrFaviconTag[];
  navigation: EntityNavigation;
  footer: EntityFooter;
  global: EntityGlobal;
  site: EntitySite;
  isNavigationInverted?: boolean;
};

Page.getLayout = function getLayout(props: TPageLayout) {
  const { page, ...rest } = props;
  return <Layout {...rest}>{page}</Layout>;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { preview, params, res } = context;
  const slug = params?.slug
    ? Array.isArray(params.slug)
      ? params.slug.join("/")
      : params.slug
    : "";

  if (
    await testRedirect({
      res,
      slug,
      preview
    })
  ) {
    // should never arrive here
    return { props: {} };
  }

  const dataSite = await request({ query: querySite(), preview });
  const dataGlobal = await request({ query: queryGlobal(), preview });
  const dataNavigation = await request({ query: queryNavigation(), preview });

  const pageVariables: TPageRequest = { slug };
  const dataPage = await request({ query: queryPage(pageVariables), preview });
  const page: IPage = dataPage?.data;

  const dataFooter = await request({ query: queryFooter(), preview });

  const hasBlockJobsAll =
    page && page.blocks ? page.blocks.find((block) => block._modelApiKey === "jobs_all") : null;

  const jobTags: IJobTags | null = hasBlockJobsAll
    ? await request({ query: queryJobTags(), preview })
    : null;

  res.setHeader("Cache-Control", "public, s-maxage=1800");
  if (!page) {
    res.setHeader("Cache-Control", "no-cache");
    res.statusCode = 404;
  }

  return {
    props: {
      preview: preview ? true : null,
      subscriptionGlobal: preview
        ? {
            ...queryGlobal(),
            preview,
            initialData: dataGlobal,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: dataGlobal
          },
      subscriptionNavigation: preview
        ? {
            ...queryNavigation(),
            preview,
            initialData: dataNavigation,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: dataNavigation
          },
      subscriptionPage: preview
        ? {
            ...queryPage(pageVariables),
            preview,
            initialData: dataPage,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: dataPage
          },
      subscriptionFooter: preview
        ? {
            ...queryFooter(),
            preview,
            initialData: dataFooter,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: dataFooter
          },
      subscriptionJobTags: preview
        ? {
            ...queryJobTags(),
            preview,
            initialData: jobTags,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: jobTags
          },
      subscriptionSite: preview
        ? {
            ...querySite(),
            preview,
            initialData: dataSite,
            token: process.env.NEXT_DATOCMS_API_TOKEN
          }
        : {
            enabled: false,
            initialData: dataSite
          }
    }
  };
};
