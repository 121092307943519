import styles from "./BlockTextLarge.module.scss";

import { IBlockTextLarge } from "@lib/types/IBlockTextLarge";

import Container from "@components/containers/Container";
import ButtonPrimary, {
  BUTTON_PRIMARY_ARROW,
  BUTTON_PRIMARY_VARIANTS
} from "@components/buttons/ButtonPrimary";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Columns from "@components/columns/Columns";
import Assets from "@components/assets/Assets";
import * as Analytics from "@lib/tracking/Analytics";

const BlockTextLarge = (props: IBlockTextLarge) => {
  return (
    <Container
      as="section"
      labelledby="Text"
      className={styles.container}
      key={props.id}
      trackingCategory={props.paragraph || "Text Large"}
    >
      <Columns>
        <div></div>
        <div>
          <div className={styles.paragraph}>
            <HeadingLarge>{props.paragraph}</HeadingLarge>
          </div>
          <ul className={styles.list}>
            {props.buttons.map((button) => {
              return (
                <li className={styles.listItem} key={button.id}>
                  <ButtonPrimary
                    onClick={() => {
                      Analytics.trackEvent("click", "text large", button.title);
                    }}
                    arrow={BUTTON_PRIMARY_ARROW.Right}
                    variant={BUTTON_PRIMARY_VARIANTS.Filled}
                    {...button}
                  >
                    {button.title}
                  </ButtonPrimary>
                </li>
              );
            })}
          </ul>
        </div>
      </Columns>
      {props.assets.length > 0 && (
        <div className={styles.assets}>
          <Assets assets={props.assets} />
        </div>
      )}
    </Container>
  );
};

export default BlockTextLarge;
