import styles from "./BlockHeaderTypeA.module.scss";

import * as React from "react";
import { IBlockHeaderTypeA } from "@lib/types/IBlockHeaderTypeA";

import ButtonHeaderTypeA from "@components/buttons/ButtonHeaderTypeA";
import Container from "@components/containers/Container";

import { Ascii } from "@webgl/Ascii";
import useWindowSize from "@hooks/useWindowSize";
import IntroText from "@components/intro-text/IntroText";
import { motion } from "framer-motion";
import * as Analytics from "@lib/tracking/Analytics";

const BlockHeaderTypeA = (props: IBlockHeaderTypeA) => {
  const { width, height } = useWindowSize();
  const [isPayoffVisible, setIsPayoffVisible] = React.useState<boolean>(false);
  const payoff = React.useRef<HTMLElement>(null);
  const canvas = React.useRef<HTMLCanvasElement>(null);

  const ascii = React.useRef<Ascii>();

  const handleComplete = () => {
    setIsPayoffVisible(true);
  };

  React.useEffect(() => {
    if (!canvas.current) return;

    if (!ascii.current) {
      ascii.current = new Ascii({
        canvas: canvas.current,
        minHeightMobile: 400,
        minHeightDesktop: 500,
        backgroundColor: [0.0, 15.0 / 255.0, 30.0 / 255.0, 1.0]
      });
    }

    if (ascii.current && width && height) {
      ascii.current.setSize(width, height);
    }
  }, [canvas, width, height]);

  React.useEffect(() => {
    return () => {
      if (ascii.current) {
        ascii.current.destroy();
      }
    };
  }, []);

  return (
    <>
      <canvas ref={canvas} width="1204" height="500" className={styles.canvas}></canvas>

      <IntroText
        copy={props.intro}
        minHeightMobile={400}
        minHeightDesktop={500}
        onComplete={handleComplete}
      />

      <Container
        as="section"
        className={styles.container}
        key={props.id}
        labelledby="Header"
        trackingCategory={"Header (Type A)"}
      >
        {props.payoff && (
          <motion.h1 className={styles.payoff} animate={{ opacity: isPayoffVisible ? 1 : 0 }}>
            {props.payoff}
          </motion.h1>
        )}
        <ul className={styles.list}>
          {props.buttons.map((button) => {
            return (
              <li className={styles.listItem} key={button.id}>
                <ButtonHeaderTypeA
                  button={button}
                  onClick={() => {
                    Analytics.trackEvent("click", "header type a", button.title);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </Container>
    </>
  );
};

export default BlockHeaderTypeA;
