import { initShader } from "./helpers/initShader";
import { initBuffers } from "./helpers/initBuffers";
import { loadTexture } from "./helpers/loadTexture";
import { CustomTexture, loadVideoTexture } from "./helpers/loadVideoTexture";

import frag from "./shaders/ascii.frag";
import vert from "./shaders/ascii.vert";

interface IAsciiProps {
  canvas: HTMLCanvasElement;
  minHeightMobile: number;
  minHeightDesktop: number;
  backgroundColor: number[];
  videoUrl?: string;
}

interface IGeometry {
  position: WebGLBuffer | null;
  textureCoord: WebGLBuffer | null;
  indices: WebGLBuffer | null;
}

interface IMaterial {
  program: WebGLProgram;
  attribLocations: {
    vertexPosition: number;
    textureCoord: number;
  };
  uniformLocations: {
    uTime: WebGLUniformLocation | null;
    uBackground: WebGLUniformLocation | null;
    uBackgroundSize: WebGLUniformLocation | null;
    uBackgroundColor: WebGLUniformLocation | null;
    uFont: WebGLUniformLocation | null;
    uFontSize: WebGLUniformLocation | null;
    uUseVideo: WebGLUniformLocation | null;
    uVideo: WebGLUniformLocation | null;
    uVideoSize: WebGLUniformLocation | null;
    uResolution: WebGLUniformLocation | null;
  };
}

interface IObject {
  textureFont: WebGLTexture | null;
  textureBackground: WebGLTexture | null;
  textureVideo: CustomTexture | null;
  material: IMaterial;
  geometry: IGeometry;
}

export class Ascii {
  private canvas: HTMLCanvasElement;
  private minHeightMobile: number;
  private minHeightDesktop: number;
  private backgroundColor: number[] = [0, 0, 0, 0];
  private width: number;
  private height: number;
  private videoUrl?: string;

  private time: number;

  private renderContexts: WebGLRenderingContext[];
  private objects: IObject[];

  private raf: any;

  constructor(props: IAsciiProps) {
    this.canvas = props.canvas;
    this.minHeightMobile = props.minHeightMobile;
    this.minHeightDesktop = props.minHeightDesktop;
    this.backgroundColor = props.backgroundColor;
    this.videoUrl = props.videoUrl;

    this.time = 0;

    this.width = 200;
    this.height = 200;

    this.renderContexts = [];
    this.objects = [];

    const gl = this.canvas.getContext("webgl", {
      premultipliedAlpha: true,
      antialias: false,
      alpha: true,
      stencil: false,
      depth: false
    });
    if (!gl) return;

    gl.clearColor(1.0, 1.0, 1.0, 0.0);
    gl.enable(gl.DEPTH_TEST);
    gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

    this.renderContexts.push(gl);

    const geometry: IGeometry = initBuffers(gl);

    const textureFont = loadTexture(gl, "/assets/images/ASCII_map.jpg", () => {
      this.draw();
    });

    const textureBackground = loadTexture(gl, "/assets/images/ASCII_background.jpg", () => {
      this.draw();
    });

    const textureVideo = this.videoUrl
      ? loadVideoTexture(gl, this.videoUrl, () => {
          this.draw();
        })
      : null;

    const material = initShader(gl, frag, vert);
    if (!material) return;

    this.objects.push({
      geometry,
      material,
      textureFont,
      textureBackground,
      textureVideo
    });

    this.draw();

    this.raf = requestAnimationFrame(this.update);
  }

  destroy = () => {
    cancelAnimationFrame(this.raf);
  };

  setSize = (width: number, height: number) => {
    this.width = width;

    const minHeight = this.width < 1024 ? this.minHeightMobile : this.minHeightDesktop;

    this.height = Math.max(minHeight, height);

    this.canvas.width = this.width;
    this.canvas.height = this.height;

    this.draw();
  };

  update = (time: number) => {
    this.time = time;
    this.draw();
    this.raf = requestAnimationFrame(this.update);
  };

  draw = () => {
    const gl = this.renderContexts[0];
    if (!gl) return;

    gl.viewport(0, 0, this.width, this.height);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    this.objects.forEach((object) => {
      gl.bindBuffer(gl.ARRAY_BUFFER, object.geometry.position);
      gl.vertexAttribPointer(
        object.material.attribLocations.vertexPosition,
        3,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(object.material.attribLocations.vertexPosition);

      gl.bindBuffer(gl.ARRAY_BUFFER, object.geometry.textureCoord);
      gl.vertexAttribPointer(
        object.material.attribLocations.textureCoord,
        2,
        gl.FLOAT,
        false,
        0,
        0
      );
      gl.enableVertexAttribArray(object.material.attribLocations.textureCoord);

      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, object.geometry.indices);

      gl.useProgram(object.material.program);

      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, object.textureFont);
      gl.uniform1i(object.material.uniformLocations.uFont, 0);
      gl.uniform2f(object.material.uniformLocations.uFontSize, 600, 100);

      gl.activeTexture(gl.TEXTURE1);
      gl.bindTexture(gl.TEXTURE_2D, object.textureBackground);
      gl.uniform1i(object.material.uniformLocations.uBackground, 1);
      gl.uniform2f(object.material.uniformLocations.uBackgroundSize, 1024, 1024);

      if (object.textureVideo) {
        gl.activeTexture(gl.TEXTURE2);
        gl.bindTexture(gl.TEXTURE_2D, object.textureVideo);
        object.textureVideo.update();
        gl.uniform1i(object.material.uniformLocations.uUseVideo, this.videoUrl ? 1 : 0);
        gl.uniform1i(object.material.uniformLocations.uVideo, 2);
        gl.uniform2f(object.material.uniformLocations.uVideoSize, 100, 100);
      }

      gl.uniform4f(
        object.material.uniformLocations.uBackgroundColor,
        this.backgroundColor[0],
        this.backgroundColor[1],
        this.backgroundColor[2],
        this.backgroundColor[3]
      );
      gl.uniform2f(object.material.uniformLocations.uResolution, this.width, this.height);
      gl.uniform1f(object.material.uniformLocations.uTime, this.time);

      gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
    });
  };
}
