import styles from "./BlockCompaniesTypeB.module.scss";

import { IBlockCompaniesTypeB } from "@lib/types/IBlockCompaniesTypeB";

import Container from "@components/containers/Container";
import Eyebrow from "@components/eyebrow/Eyebrow";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Columns, { COLUMN_VARIANTS } from "@components/columns/Columns";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";
import useWindowSize from "@hooks/useWindowSize";

const BlockCompaniesTypeB = (props: IBlockCompaniesTypeB) => {
  const { width } = useWindowSize();

  const desiredImageHeight = width ? (width < 1024 ? 50 : 100) : 100;

  return (
    <Container
      as="section"
      labelledby="Clients"
      key={props.id}
      trackingCategory={"Companies (Type B)"}
      className={styles.container}
    >
      <Columns variant={COLUMN_VARIANTS.Wide} className={styles.columns}>
        <div>
          <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
        </div>
        <HeadingLarge as="span">{props.heading}</HeadingLarge>
      </Columns>
      <ul className={styles.list}>
        {props.companies.map((company) => {
          return (
            <li className={styles.listItem} key={company.id}>
              <div
                className={styles.company}
                style={{
                  height: desiredImageHeight,
                  width:
                    (company.logoBlack.responsiveImage.width * desiredImageHeight) /
                    (company.logoBlack.responsiveImage.height || 1)
                }}
              >
                <ResponsiveImage
                  {...company.logoWhite}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="0% 50%"
                  fadeInDuration={0}
                  usePlaceholder={false}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default BlockCompaniesTypeB;
