import styles from "./Pagination.module.scss";

import * as React from "react";
import Button from "@components/buttons/Button";

export type TPagination = {
  size: number;
  offset?: number;
  total?: number;
  onClick?: (page: any) => void; // TODO define page
};

const Pagination = (props: TPagination) => {
  const { total = 0, offset = 0, size } = props;

  if (!total) {
    return null;
  }

  const amount = Math.ceil(total / size);
  const max = amount * size;
  const active = total < size ? 0 : Math.floor((offset / max) * amount);

  const prev = {
    offset: Math.max(0, offset - size),
    size
  };

  const next = {
    offset: offset + size,
    size
  };

  return (
    <div className={styles.container}>
      <Button
        type="button"
        className={styles.button}
        onClick={() => {
          if (props.onClick) {
            props.onClick(prev);
          }
        }}
        disabled={offset <= 0}
      >
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 22L13 16L19 10" strokeWidth="2" strokeLinejoin="round" />
        </svg>
      </Button>

      <ul className={styles.pagination}>
        {Array.from({ length: amount }, (_, i) => {
          return {
            value: i + 1,
            offset: i * size,
            size
          };
        }).map((page, index) => {
          const className = index === active ? styles.buttonActive : styles.button;
          return (
            <li key={index}>
              <Button
                type="button"
                className={className}
                onClick={() => {
                  if (props.onClick) {
                    props.onClick(page);
                  }
                }}
              >
                {page.value}
              </Button>
            </li>
          );
        })}
      </ul>

      <Button
        type="button"
        className={styles.button}
        onClick={() => {
          if (props.onClick) {
            props.onClick(next);
          }
        }}
        disabled={offset >= total - size}
      >
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 10L19 16L13 22" strokeWidth="2" strokeLinejoin="round" />
        </svg>
      </Button>
    </div>
  );
};

export default Pagination;
