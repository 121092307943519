import styles from "./OpenRoles.module.scss";

import * as React from "react";

export type TOpenRolesProps = {
  jobsTotal: number;
  single: string;
  plural: string;
  as?: string;
};

const OpenRoles = (props: TOpenRolesProps) => {
  const Tag = (props.as || "div") as keyof JSX.IntrinsicElements;
  let copy =
    props.jobsTotal === 1
      ? props.single.replace("[value]", props.jobsTotal.toString())
      : props.plural.replace("[value]", props.jobsTotal.toString());
  return <Tag className={styles.copy}>{copy}</Tag>;
};

export default OpenRoles;
