import { NextRouter } from "next/router";
import { UrlObject } from "url";

export interface IJobQuery {
  slug: string[];
  size: number;
  offset: number;
  filter: string[];
}

export const handleFilter =
  (
    router: NextRouter,
    setCurrentFilter: React.Dispatch<React.SetStateAction<IJobQuery>>,
    query: IJobQuery
  ) =>
  () => {
    setCurrentFilter(query);

    // FIXME: fix the any
    const url: UrlObject = {
      query: query as any
    };
    // router.push(url, undefined, { scroll: false, shallow: true });
  };
