import { createFragmentShader } from "./createFragmentShader";
import { createVertexShader } from "./createVertexShader";

export function initShader(gl: WebGLRenderingContext, frag: string, vert: string) {
  const shaderProgram = gl.createProgram();
  const fragmentShader = createFragmentShader(gl, frag);
  const vertexShader = createVertexShader(gl, vert);

  if (!shaderProgram) return null;
  if (!fragmentShader) return null;
  if (!vertexShader) return null;

  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    console.error("Could not initialise shaders");
    return null;
  }

  gl.useProgram(shaderProgram);

  return {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord")
    },
    uniformLocations: {
      uTime: gl.getUniformLocation(shaderProgram, "uTime"),
      uFont: gl.getUniformLocation(shaderProgram, "uFont"),
      uFontSize: gl.getUniformLocation(shaderProgram, "uFontSize"),
      uBackground: gl.getUniformLocation(shaderProgram, "uBackground"),
      uBackgroundSize: gl.getUniformLocation(shaderProgram, "uBackgroundSize"),
      uBackgroundColor: gl.getUniformLocation(shaderProgram, "uBackgroundColor"),
      uUseVideo: gl.getUniformLocation(shaderProgram, "uUseVideo"),
      uVideo: gl.getUniformLocation(shaderProgram, "uVideo"),
      uVideoSize: gl.getUniformLocation(shaderProgram, "uVideoSize"),
      uResolution: gl.getUniformLocation(shaderProgram, "uResolution")
    }
  };
}
