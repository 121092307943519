import styles from "./HorizontalScroll.module.scss";

import * as React from "react";
import { handleMouseDown } from "./handlers";
import { cx } from "helpers/classNames";
import { trackEvent } from "@lib/tracking/Analytics";

export type THorizontalScrollProps = {
  className?: string;
  children: React.ReactNode;
};

const HorizontalScroll = (props: THorizontalScrollProps) => {
  const [didInteract, setDidInteract] = React.useState(false);
  const scroller = React.useRef(null);
  const className = cx(props.className, styles.container);

  return (
    <div
      ref={scroller}
      className={className}
      onMouseDown={handleMouseDown({ scroller })}
      onScroll={() => {
        if (!didInteract) {
          trackEvent("scroll-horizontal", "how we work", "true");
        }
        setDidInteract(true);
      }}
    >
      {props.children}
    </div>
  );
};

export default HorizontalScroll;
