import styles from "./BlockTextImage.module.scss";

import * as React from "react";
import { IBlockTextImage } from "@lib/types/IBlockTextImage";

import Container from "@components/containers/Container";
import Eyebrow from "@components/eyebrow/Eyebrow";
import Columns, { COLUMN_VARIANTS } from "@components/columns/Columns";
import Divider from "@components/divider/Divider";
import BodyLarge, { BODY_LARGE_VARIANTS } from "@components/bodyLarge/BodyLarge";
import Assets from "@components/assets/Assets";
import RichText from "@components/rich-text/RichText";

import { Ascii } from "@webgl/Ascii";

const BlockTextImage = (props: IBlockTextImage) => {
  const width = 520;
  const height = 520;

  const canvas = React.useRef<HTMLCanvasElement>(null);

  const ascii = React.useRef<Ascii>();

  React.useEffect(() => {
    if (!canvas.current) return;

    if (!ascii.current) {
      ascii.current = new Ascii({
        canvas: canvas.current,
        minHeightDesktop: 0,
        minHeightMobile: 0,
        backgroundColor: [0, 0, 0, 0],
        videoUrl: "/assets/videos/gradient.mp4"
      });
    }

    if (ascii.current && width && height) {
      ascii.current.setSize(width, height);
    }
  }, [canvas]);

  React.useEffect(() => {
    return () => {
      if (ascii.current) {
        ascii.current.destroy();
      }
    };
  }, []);

  return (
    <Container
      as="section"
      labelledby="Text"
      className={styles.container}
      key={props.id}
      trackingCategory={props.eyebrow || "Text Image"}
    >
      <Divider className={styles.divider} />
      <Columns variant={COLUMN_VARIANTS.Wide} className={styles.columns}>
        <div>
          <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
        </div>
        <BodyLarge as="div" variant={props.indent ? BODY_LARGE_VARIANTS.Indent : undefined}>
          <RichText className={styles.richtext} richtext={props.paragraph} />
        </BodyLarge>
      </Columns>

      {props.assets.length > 0 && (
        <div className={styles.assets}>
          <canvas ref={canvas} width="520" height="520" className={styles.canvas}></canvas>
          <Assets assets={props.assets} />
        </div>
      )}
    </Container>
  );
};

export default BlockTextImage;
