import styles from "./BlockOurProcess.module.scss";

import * as React from "react";
import { IBlockOurProcess } from "@lib/types/IBlockOurProcess";

import Container from "@components/containers/Container";
import Eyebrow from "@components/eyebrow/Eyebrow";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import BodyLarge from "@components/bodyLarge/BodyLarge";
import OurProcessItem from "@components/ourProcessItem/OurProcessItem";
import useElementOffsetTop from "@hooks/useElementOffsetTop";
import useWindowSize from "@hooks/useWindowSize";
import Divider from "@components/divider/Divider";

const BlockOurProcess = (props: IBlockOurProcess) => {
  const list = React.useRef(null);
  const { height } = useWindowSize();
  const containerOffsetTop = useElementOffsetTop(list);

  const halfHeight = height ? height / 2 : 0;
  const lineHeight = Math.round(Math.max(-1 * containerOffsetTop + halfHeight, 0));

  return (
    <Container
      as="section"
      labelledby="Our Process"
      className={styles.container}
      key={props.id}
      trackingCategory={"Our Process"}
    >
      <Divider className={styles.divider} />
      <div className={styles.eyebrow}>
        <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
      </div>
      <div className={styles.heading}>
        <HeadingLarge as="span" className={styles.heading}>
          {props.heading}
        </HeadingLarge>
      </div>
      <div className={styles.body}>
        <BodyLarge>{props.paragraph}</BodyLarge>
      </div>
      <div className={styles.listWrapper}>
        <div
          className={styles.line}
          style={{ clipPath: `polygon(0% 0%,100% 0%,100% ${lineHeight}px,0% ${lineHeight}px)` }}
        ></div>
        <ul className={styles.list} ref={list}>
          {props.items.map((item) => {
            return (
              <li key={item.id} className={styles.listItem}>
                <OurProcessItem {...item} />
              </li>
            );
          })}
        </ul>
      </div>
    </Container>
  );
};

export default BlockOurProcess;
