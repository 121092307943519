import styles from "./BlockContactMapAndForm.module.scss";

import Container from "@components/containers/Container";
import MyForm from "@components/form/Form";
import { EntityBlockContactMapAndForm } from "@lib/entities/EntityBlockContactMapAndForm";
import { ASSETS } from "@lib/enums/assets";
import Image from "@components/image/Image";
import Map from "@components/map/Map";
import { EntityGlobal } from "@lib/entities/EntityGlobal";

interface IProps extends EntityBlockContactMapAndForm {
  global: EntityGlobal;
}

const BlockContactMapAndForm = (props: IProps) => {
  return (
    <Container
      as="section"
      labelledby="Contact Form"
      trackingCategory={"Contact Map And Form"}
      className={styles.container}
      key={props.id}
    >
      <div className={styles.columns}>
        <div>
          {props.assets.map((asset, index) => {
            switch (asset._modelApiKey) {
              case ASSETS.Image: {
                return (
                  <div className={styles.asset} key={index}>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <Image {...asset} />
                  </div>
                );
              }
              case ASSETS.Map: {
                return (
                  <div className={styles.asset} key={index}>
                    <Map {...asset} />
                  </div>
                );
              }
            }
          })}
        </div>
        <div>
          {props.form.map((form) => {
            return <MyForm key={form.id} form={form} global={props.global} />;
          })}
        </div>
      </div>
    </Container>
  );
};

export default BlockContactMapAndForm;
