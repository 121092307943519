import styles from "./Map.module.scss";

import * as React from "react";
import { EntityMap } from "@lib/entities/EntityMap";
import ResponsiveImage from "@components/responsive-image/ResponsiveImage";
import Button from "@components/buttons/Button";

import * as Analytics from "@lib/tracking/Analytics";
import { EntityButtonExternal } from "@lib/entities/EntityButtonExternal";

const Map = (props: EntityMap) => {
  return (
    <div className={styles.map}>
      <ResponsiveImage {...props.image} layout="responsive" />

      {props.positionX && props.positionY && (
        <div
          className={styles.dotWrapper}
          style={{ left: `${props.positionX}%`, top: `${props.positionY}%` }}
        >
          <div className={styles.dot}></div>
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{props.title}</div>
          {props.buttons.length > 0 && (
            <ul className={styles.list}>
              {props.buttons.map((button) => {
                return (
                  <li className={styles.listItem} key={button.id}>
                    <Button
                      className={styles.button}
                      {...button}
                      onClick={(e) => {
                        const isExternal = button.constructor === EntityButtonExternal;
                        if (isExternal) e.preventDefault();
                        Analytics.trackEvent("click", "map", button.title);
                        if (isExternal) {
                          window.open(button.url);
                        }
                      }}
                    >
                      {button.title}
                    </Button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className={styles.location}>{props.location}</div>
      </div>
    </div>
  );
};

export default Map;
