import styles from "./ButtonPillRemove.module.scss";

import * as React from "react";
import Button from "@components/buttons/Button";

interface IButtonPillRemove {
  children: React.ReactNode;
  onClick?: () => void;
}

const ButtonPillRemove = (props: IButtonPillRemove) => {
  return (
    <Button onClick={props.onClick} className={styles.button}>
      <span>{props.children}</span>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10 22L16 16L22 22" strokeWidth="2" strokeLinejoin="round" />
        <path d="M22 10L16 16L10 10" strokeWidth="2" strokeLinejoin="round" />
      </svg>
    </Button>
  );
};

export default ButtonPillRemove;
