import styles from "./BlockJobsAll.module.scss";

import { useRouter } from "next/router";

import * as React from "react";
import { IBlockJobsAll } from "@lib/types/IBlockJobsAll";

import Container from "@components/containers/Container";

import Columns from "@components/columns/Columns";
import { handleFilter, IJobQuery } from "./handlers";
import JobsFilter from "@components/jobsFilter/JobsFilter";
import { TJobsRequest } from "@lib/queries/jobs";
import ButtonPillRemove from "@components/button-pill-remove/ButtonPillRemove";
import OpenRoles from "@components/openRoles/OpenRoles";
import { Formik, Form as FormikForm } from "formik";
import JobsFilterSubmitListener from "@components/jobsFilter/JobsFilterSubmitListener";
import useWindowSize from "@hooks/useWindowSize";
import JobsFilterMobile from "@components/jobsFilter/JobsFilterMobile";
import JobsList from "./JobsList";
import { useJobs } from "@hooks/useJobs";
import { EntityJobTags } from "@lib/entities/EntityJobTags";
import { EntityGlobal } from "@lib/entities/EntityGlobal";

const DEFAULT_OFFSET = 0;
const DEFAULT_SIZE = 20;

interface IBlockJobsAllProps extends IBlockJobsAll {
  jobTags: EntityJobTags;
  global: EntityGlobal;
}

const BlockJobsAll = (props: IBlockJobsAllProps) => {
  const { width } = useWindowSize();

  const router = useRouter();
  let {
    slug = [],
    offset = DEFAULT_OFFSET,
    size = props.defaultSize || DEFAULT_SIZE,
    filter
  } = router.query;

  size = Number(size);
  offset = Number(offset);

  const [currentFilter, setCurrentFilter] = React.useState<IJobQuery>({
    // page
    slug: Array.isArray(slug) ? slug : [slug],
    // filter
    filter: Array.isArray(filter) ? filter : filter ? [filter] : [],
    // pagination
    offset: Number(offset),
    size: Number(size)
  });

  const allSelectedEntities = props.jobTags.getAllSelectedEntities(currentFilter.filter);

  const request: TJobsRequest = {
    offset: currentFilter.offset,
    size: currentFilter.size,
    tags: allSelectedEntities.map((item) => {
      return item.id;
    })
  };

  const initialValues = allSelectedEntities.reduce(function (prev: { [id: string]: string }, curr) {
    prev[curr.id] = curr.id;
    return prev;
  }, {});

  const { data, count: jobsTotal } = useJobs(request);

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const selection = Object.entries(values)
            .filter(([key, value]) => value && value.length > 0)
            .map(([key, value]) => key);
          const filter = props.jobTags.getAllSelectedEntities(selection).map((tag) => tag.slug);

          const newQuery = Object.assign(
            currentFilter,
            { filter },
            {
              offset,
              size
            }
          );

          const parsedQuery = JSON.parse(JSON.stringify(newQuery));

          // immediatly update values in DOM
          handleFilter(router, setCurrentFilter, parsedQuery)();
        }}
      >
        {(formik) => (
          <FormikForm className={styles.form}>
            <>
              {/* <JobsFilterSubmitListener formik={formik} /> */}

              {/* {width && width < 1024 && (
                <JobsFilterMobile
                  jobTags={props.jobTags}
                  jobsTotal={jobsTotal}
                  allSelectedEntities={allSelectedEntities}
                  formik={formik}
                />
              )}
              {width && width >= 1024 && (
                <JobsFilter
                  jobTags={props.jobTags}
                  jobsTotal={jobsTotal}
                  allSelectedEntities={allSelectedEntities}
                  formik={formik}
                />
              )} */}

              <div className={styles.divider}></div>

              <Container
                as="section"
                labelledby="All Jobs"
                key={props.id}
                trackingCategory={"Jobs All"}
              >
                <div className={styles.content}>
                  <Columns>
                    <OpenRoles
                      as="h1"
                      jobsTotal={jobsTotal}
                      single="[value] open role"
                      plural="[value] open roles"
                    />
                    <div>
                      <div className={styles.containerButtonPill}>
                        {allSelectedEntities.length > 0 &&
                          allSelectedEntities.map((tag) => {
                            return (
                              <ButtonPillRemove
                                key={tag.id}
                                onClick={() => {
                                  formik.setFieldValue(tag.id, null);
                                }}
                              >
                                {tag.title}
                              </ButtonPillRemove>
                            );
                          })}
                      </div>
                      <JobsList
                        request={request}
                        currentFilter={currentFilter}
                        setCurrentFilter={setCurrentFilter}
                        global={props.global}
                      />
                    </div>
                  </Columns>
                </div>
              </Container>
            </>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default BlockJobsAll;
