import styles from "./BlockHowWeWork.module.scss";

import { IBlockHowWeWork } from "@lib/types/IBlockHowWeWork";

import Container from "@components/containers/Container";
import Divider from "@components/divider/Divider";
import Columns, { COLUMN_VARIANTS } from "@components/columns/Columns";
import Eyebrow from "@components/eyebrow/Eyebrow";
import BodyLarge, { BODY_LARGE_VARIANTS } from "@components/bodyLarge/BodyLarge";
import HowWeWorkItem from "@components/how-we-work-item/HowWeWorkItem";
import HorizontalScroll from "@components/horizontalScroll/HorizontalScroll";
import Section from "@components/section/Section";
import { motion } from "framer-motion";

const BlockHowWeWork = (props: IBlockHowWeWork) => {
  return (
    <Section labelledby="How We Work" className={styles.container} trackingCategory={"How We Work"}>
      <Container key={props.id}>
        <Divider className={styles.divider} />
        <Columns variant={COLUMN_VARIANTS.Wide}>
          <div>
            <Eyebrow as="h1">{props.eyebrow}</Eyebrow>
          </div>
          <BodyLarge variant={BODY_LARGE_VARIANTS.Indent}>{props.paragraph}</BodyLarge>
        </Columns>
      </Container>
      <div className={styles.scrollerWrapper}>
        <motion.div
          style={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0, margin: "0px 0px -100px 0px" }}
          transition={{ ease: "easeOut", duration: 0.6 }}
          viewport={{ once: true }}
        >
          {props.items.length > 0 && (
            <HorizontalScroll className={styles.scroller}>
              <Container overflow key={props.id}>
                <Columns>
                  <div></div>
                  <div className={styles.items}>
                    {props.items.map((item) => {
                      return <HowWeWorkItem key={item.id} {...item} />;
                    })}
                  </div>
                </Columns>
              </Container>
            </HorizontalScroll>
          )}
        </motion.div>
      </div>
    </Section>
  );
};

export default BlockHowWeWork;
