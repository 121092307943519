import { IReferenceClient } from "@lib/types/IReferenceClient";
import { useEffect, useState } from "react";
import useSWR from "swr";

interface IReferenceClientsResponse {
  data?: IReferenceClient[];
  count: number;
  isLoading: boolean;
  isError: boolean;
}

interface IProps {
  active: boolean;
}

export const useReferenceClients = (props: IProps): IReferenceClientsResponse => {
  const { active } = props;
  const [data, setData] = useState<IReferenceClient[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (active) {
      fetch(`/api/reference-clients`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw "Error loading";
          }
        })
        .then((data) => {
          setData(data?.data);
          setCount(data?.metadata.count);
          setIsLoading(false);
        })
        .catch(() => {
          setIsError(true);
        });
    }
  }, [active]);

  return {
    data,
    count,
    isLoading,
    isError
  };
};
