import * as React from "react";
import { motion, useViewportScroll } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";

interface IProps {
  className?: string;
}

const Dot = (props: IProps) => {
  const dot = React.useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const { width, height } = useWindowSize();

  const { scrollY } = useViewportScroll();

  React.useEffect(() => {
    const unsubscribeY = scrollY.onChange((y) => {
      if (width && width >= 1024) {
        if (dot.current) {
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = dot.current.getBoundingClientRect();
          const offset = elemRect.top - bodyRect.top;
          const isVisible = y - offset + (height || 0) * 0.5 > 0;
          setIsVisible(isVisible);
        }
      }
    });

    return () => {
      unsubscribeY();
    };
  }, [scrollY, width, height]);

  return (
    <motion.div
      ref={dot}
      className={props.className}
      animate={{ scale: isVisible ? 1 : 0 }}
      transition={{ ease: "backOut", duration: 0.4 }}
    />
  );
};

export default Dot;
