export function createFragmentShader(gl: WebGLRenderingContext, str: string) {
  var shader = gl.createShader(gl.FRAGMENT_SHADER);
  if (!shader) return null;

  gl.shaderSource(shader, str);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    alert(gl.getShaderInfoLog(shader));
    return null;
  }

  return shader;
}
