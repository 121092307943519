import { cx } from "helpers/classNames";
import styles from "./Divider.module.scss";

interface IDivider {
  className?: string;
}

const Divider = (props: IDivider) => {
  const classNames = cx(styles.divider, props.className);
  return <div className={classNames}></div>;
};

export default Divider;
