import styles from "./BlockJobsHighlighted.module.scss";

import * as React from "react";
import { IBlockJobsHighlighted } from "@lib/types/IBlockJobsHighlighted";

import Container from "@components/containers/Container";
import ButtonPrimary, {
  BUTTON_PRIMARY_ARROW,
  BUTTON_PRIMARY_VARIANTS
} from "@components/buttons/ButtonPrimary";
import Eyebrow from "@components/eyebrow/Eyebrow";
import HeadingLarge from "@components/heading-large/HeadingLarge";
import Columns from "@components/columns/Columns";
import ButtonJob from "@components/button-job/ButtonJob";
import Divider from "@components/divider/Divider";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import * as Analytics from "@lib/tracking/Analytics";

interface IProps extends IBlockJobsHighlighted {
  global: EntityGlobal;
}

const BlockJobsHighlighted = (props: IProps) => {
  return (
    <Container
      className={styles.container}
      key={props.id}
      as="section"
      labelledby="Highlighted Jobs"
      trackingCategory={"Jobs Highlighted"}
    >
      <Divider className={styles.divider} />
      <Columns>
        <div>
          <Eyebrow>{props.eyebrow}</Eyebrow>
        </div>
        <div>
          <div className={styles.heading}>
            <HeadingLarge as="h1">{props.heading}</HeadingLarge>
          </div>
          <ul className={styles.listJobs}>
            {props.jobs.map((job) => {
              return (
                <li className={styles.listJobsItem} key={job.id}>
                  <ButtonJob job={job} global={props.global} />
                </li>
              );
            })}
          </ul>
          <ul className={styles.list}>
            {props.buttons.map((item) => {
              return (
                <li className={styles.listItem} key={item.id}>
                  <ButtonPrimary
                    onClick={() => {
                      Analytics.trackEvent("click", "jobs highlighted", item.title);
                    }}
                    arrow={BUTTON_PRIMARY_ARROW.Right}
                    variant={BUTTON_PRIMARY_VARIANTS.Filled}
                    {...item}
                  >
                    {item.title}
                  </ButtonPrimary>
                </li>
              );
            })}
          </ul>
        </div>
      </Columns>
    </Container>
  );
};

export default BlockJobsHighlighted;
