import * as React from "react";

import { BLOCKS } from "@lib/enums/blocks";
import { TBlocks, TBlock } from "@lib/types/TBlocks";

import BlockCompaniesTypeA from "./BlockCompaniesTypeA/BlockCompaniesTypeA";
import BlockCompaniesTypeB from "./BlockCompaniesTypeB/BlockCompaniesTypeB";
import BlockHeaderContact from "./BlockHeaderContact/BlockHeaderContact";
import BlockHeaderTypeA from "./BlockHeaderTypeA/BlockHeaderTypeA";
import BlockHeaderTypeB from "./BlockHeaderTypeB/BlockHeaderTypeB";
import BlockJobsAll from "./BlockJobsAll/BlockJobsAll";
import BlockJobsHighlighted from "./BlockJobsHighlighted/BlockJobsHighlighted";
import BlockHowWeWork from "./BlockHowWeWork/BlockHowWeWork";
import BlockTextImage from "./BlockTextImage/BlockTextImage";
import BlockTextLarge from "./BlockTextLarge/BlockTextLarge";
import BlockMeetTheTeam from "./BlockMeetTheTeam/BlockMeetTheTeam";
import BlockContactMapAndForm from "./BlockContactMapAndForm/BlockContactMapAndForm";
import BlockTestimonial from "./BlockTestimonial/BlockTestimonial";
import BlockTestimonialClient from "./BlockTestimonialClient/BlockTestimonialClient";
import BlockOurProcess from "./BlockOurProcess/BlockOurProcess";
import BlockPurpleBanner from "./BlockPurpleBanner/BlockPurpleBanner";
import { EntityJobTags } from "@lib/entities/EntityJobTags";
import { EntityGlobal } from "@lib/entities/EntityGlobal";

function assertUnreachable(modelApiKey: string): never {
  throw new Error(`Unknown block '${modelApiKey}'`);
}

interface AllBlocksProps {
  blocks: TBlocks;
  jobTags: EntityJobTags;
  global: EntityGlobal;
}

const AllBlocks = ({ blocks, jobTags, global }: AllBlocksProps) => {
  function getBlock(block: TBlock) {
    switch (block._modelApiKey) {
      case BLOCKS.BlockCompaniesTypeA: {
        return <BlockCompaniesTypeA key={block.id} {...block} />;
      }
      case BLOCKS.BlockCompaniesTypeB: {
        return <BlockCompaniesTypeB key={block.id} {...block} />;
      }
      case BLOCKS.BlockHeaderContact: {
        return <BlockHeaderContact key={block.id} {...block} />;
      }
      case BLOCKS.BlockHeaderTypeA: {
        return <BlockHeaderTypeA key={block.id} {...block} />;
      }
      case BLOCKS.BlockHeaderTypeB: {
        return <BlockHeaderTypeB key={block.id} {...block} />;
      }
      case BLOCKS.BlockTextImage: {
        return <BlockTextImage key={block.id} {...block} />;
      }
      case BLOCKS.BlockTextLarge: {
        return <BlockTextLarge key={block.id} {...block} />;
      }
      case BLOCKS.BlockJobsAll: {
        return <BlockJobsAll key={block.id} {...block} jobTags={jobTags} global={global} />;
      }
      case BLOCKS.BlockJobsHighlighted: {
        return <BlockJobsHighlighted key={block.id} {...block} global={global} />;
      }
      case BLOCKS.BlockHowWeWork: {
        return <BlockHowWeWork key={block.id} {...block} />;
      }
      case BLOCKS.BlockMeetTheTeam: {
        return <BlockMeetTheTeam key={block.id} {...block} />;
      }
      case BLOCKS.BlockContactMapAndForm: {
        return <BlockContactMapAndForm key={block.id} {...block} global={global} />;
      }
      case BLOCKS.BlockTestimonial: {
        return <BlockTestimonial key={block.id} {...block} />;
      }
      case BLOCKS.BlockTestimonialClient: {
        return <BlockTestimonialClient key={block.id} {...block} />;
      }
      case BLOCKS.BlockOurProcess: {
        return <BlockOurProcess key={block.id} {...block} />;
      }
      case BLOCKS.BlockPurpleBanner: {
        return <BlockPurpleBanner key={block.id} {...block} />;
      }
      default:
        assertUnreachable(block._modelApiKey);
    }
  }

  return <>{blocks.map(getBlock)}</>;
};

export default AllBlocks;
