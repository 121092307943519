import { IJobTags } from "../types/IJobTags";
import { EntityJobTagGroup } from "./EntityJobTagGroup";
import { EntityJobTag } from "./EntityJobTag";
import { notEmpty } from "helpers/notEmpty";

export class EntityJobTags implements IJobTags {
  constructor(public allJobTagGroups: EntityJobTagGroup[], public allJobTags: EntityJobTag[]) {}

  static createFromObject(parameters: IJobTags): EntityJobTags {
    return new EntityJobTags(
      parameters.allJobTagGroups
        ? parameters.allJobTagGroups.map((item) => {
            return EntityJobTagGroup.createFromObject(item);
          })
        : [],
      parameters.allJobTags
        ? parameters.allJobTags.map((item) => {
            return EntityJobTag.createFromObject(item);
          })
        : []
    );
  }

  //get selected tags from array with tag ids
  getAllSelectedEntities = (filter: string[]): EntityJobTag[] => {
    return filter
      .map((idOrSlug) => {
        return this.allJobTags.find((item) => {
          return item.slug === idOrSlug || item.id === idOrSlug;
        });
      })
      .filter(notEmpty);
  };

  getTagsFromGroup = (group: EntityJobTagGroup): EntityJobTag[] => {
    return this.allJobTags
      .filter((tag) => {
        return tag.tagGroup.id === group.id;
      })
      .filter(notEmpty);
  };
}
