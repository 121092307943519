import styles from "./Eyebrow.module.scss";

import * as React from "react";
interface IEyebrow {
  children: React.ReactNode;
  as?: string;
}

const Eyebrow = (props: IEyebrow) => {
  if (!props.children) {
    return null;
  }

  const Tag = (props.as || "span") as keyof JSX.IntrinsicElements;
  return <Tag className={styles.text}>{props.children}</Tag>;
};

export default Eyebrow;
