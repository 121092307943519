import styles from "./JobsList.module.scss";

import * as React from "react";

import ButtonJob from "@components/button-job/ButtonJob";

import { EntityJob } from "@lib/entities/EntityJob";
import Pagination from "@components/pagination/Pagination";
import { handleFilter, IJobQuery } from "./handlers";
import { TJobsRequest } from "@lib/queries/jobs";
import { useRouter } from "next/router";
import { useJobs } from "@hooks/useJobs";
import { EntityGlobal } from "@lib/entities/EntityGlobal";
import Loader from "@components/loader/Loader";
import * as Analytics from "@lib/tracking/Analytics";

interface IJobsListProps {
  request: TJobsRequest;
  currentFilter: IJobQuery;
  setCurrentFilter: React.Dispatch<React.SetStateAction<IJobQuery>>;
  global: EntityGlobal;
}

const JobsList = (props: IJobsListProps) => {
  const router = useRouter();
  const { request, currentFilter } = props;

  const { data, count, isError, isLoading } = useJobs(request);

  if (isError) return <div>failed to load</div>;
  if (isLoading) return <Loader />;

  const jobs =
    data &&
    data.map((job) => {
      return EntityJob.createFromObject(job);
    });
  const jobsTotal = count;

  const filterLength = currentFilter.filter.length;

  return (
    <>
      {jobs && jobs.length > 0 && (
        <ul className={styles.listJobs}>
          {jobs.map((job) => {
            return (
              <li className={styles.listJobsItem} key={job.id}>
                <ButtonJob job={job} global={props.global} />
              </li>
            );
          })}
        </ul>
      )}

      {jobs && jobs.length === 0 && (
        <div className={styles.noJobsFound}>
          {filterLength > 0
            ? `Sorry, couldn't find any jobs matching the filters.`
            : `Sorry, couldn't find any jobs.`}
        </div>
      )}

      {jobs && jobs.length > 0 && (
        <Pagination
          offset={currentFilter.offset}
          size={currentFilter.size}
          total={jobsTotal}
          onClick={(page) => {
            const newQuery = Object.assign(currentFilter, {
              offset: page.offset,
              size: page.size
            });

            const parsedQuery = JSON.parse(JSON.stringify(newQuery));

            handleFilter(router, props.setCurrentFilter, parsedQuery)();
          }}
        />
      )}
    </>
  );
};

export default JobsList;
