import styles from "./Columns.module.scss";

import * as React from "react";
import { cx } from "helpers/classNames";

export enum COLUMN_VARIANTS {
  Wide = "wide"
}

export type TColumns = {
  className?: string;
  variant?: COLUMN_VARIANTS;
  children: React.ReactNode;
};

const getClassName = (variant: COLUMN_VARIANTS | undefined) => {
  switch (variant) {
    case COLUMN_VARIANTS.Wide: {
      return styles.containerWide;
    }
    default: {
      return styles.container;
    }
  }
};

const Columns = (props: TColumns) => {
  const classNames = cx(getClassName(props.variant), props.className);
  return <div className={classNames}>{props.children}</div>;
};

export default Columns;
