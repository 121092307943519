import { TJobsRequest } from "@lib/queries/jobs";
import { IJobs } from "@lib/types/IJobs";
import useSWR from "swr";

interface IJobsResponse {
  data?: IJobs;
  count: number;
  isLoading: boolean;
  isError: boolean;
}

const fetcher = (endpoint: string, request: TJobsRequest) => () =>
  fetch(endpoint, {
    method: "POST",
    body: JSON.stringify(request)
  }).then((res) => res.json());

const objectToQuerystring = (obj: any) => {
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
};

export const useJobs = (request: TJobsRequest): IJobsResponse => {
  const qs = objectToQuerystring(request);
  const { data, error } = useSWR(`/api/jobs?${qs}`, fetcher(`/api/jobs/`, request));

  return {
    data: data?.data,
    count: data?.metadata.count || 0,
    isLoading: !error && !data,
    isError: !!error
  };
};
