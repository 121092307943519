import React from "react";

type THandlerProps = {
  scroller?: React.RefObject<HTMLDivElement>;
};

export const handleMouseDown = (props: THandlerProps) => (e: React.MouseEvent<HTMLDivElement>) => {
  const { scroller } = props;
  if (!scroller?.current) return;

  scroller.current.style.cursor = "grabbing";
  scroller.current.style.userSelect = "none";

  const pos = {
    // The current scroll
    left: scroller.current.scrollLeft,
    top: scroller.current.scrollTop,
    // Get the current mouse position
    x: e.clientX,
    y: e.clientY
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (!scroller?.current) return;
    scroller.current.style.cursor = "grab";
    scroller.current.style.removeProperty("user-select");

    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!scroller?.current) return;
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    scroller.current.scrollTop = pos.top - dy;
    scroller.current.scrollLeft = pos.left - dx;
  };

  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("mouseup", handleMouseUp);
};
